<template>
  <th-page-wrapper
    class="voucher-edit"
    :title="$t('common.resource.voucher.singular')"
    color="var(--secondary-color)"
    align-title="center"
    :actions="actions"
    @save="handleSubmit"
    @close-requested="$router.push({ name: 'vouchers-list' })"
  >
    <voucher-form
      ref="form"
      :is-new="isNew"
      :current-default-currency="currentDefaultCurrency"
    />

    <!-- V2 -->
    <div class="flex justify-end mx-8 my-4">
      <router-link
        :to="{
          name: isNew ? 'voucher-new' : 'voucher-edit',
          params: $route.params
        }"
      >
        <el-button plain>
          {{ $t('common.forms.labels.new_flow') }}
        </el-button>
      </router-link>
    </div>
  </th-page-wrapper>
</template>

<script>
import VoucherForm from './components/form.vue'

export default {
  name: 'VouchersEdit',
  components: {
    VoucherForm
  },
  props: {
    currentDefaultCurrency: {
      type: String,
      required: false,
      default: undefined
    }
  },
  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'loyalty',
        returnedPath: { name: 'vouchers-list' },
        excludeButtons: ['delete'],
        isNew: this.isNew
      }
    }
  },
  mounted() {},
  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    }
  }
}
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  align-content: center;
  align-items: center;
  padding: 0px 20px;
  height: 100%;
}
</style>
