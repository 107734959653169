<template>
  <div class="form-wrapper">
    <div class="edit-form-wrapper">
      <el-form
        ref="form"
        v-loading="loading"
        :model="form"
        :rules="rules"
        label-position="top"
        label-width="120px"
        class="edit-form"
      >
        <el-row :gutter="0">
          <el-col
            class="form-column"
            :xs="24"
            :sm="18"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <!-- Voucher System -->
            <el-form-item
              class="attributes-group"
              :label="$t('pages.vouchers.edit.form.system.label')"
              prop="system"
            >
              <th-resource-select
                v-model="_selectedSystem"
                class="system-select"
                :computed-fields="['name']"
                :resource-query="{ query: { deleted: false } }"
                resource="voucherSystems"
                :options="resources.voucher_systems"
                data-testid="systemSelect"
              />
            </el-form-item>

            <!-- Voucher System Template -->
            <el-form-item
              v-if="form.system"
              class="attributes-group"
              :label="$t('pages.vouchers.edit.form.system_template.label')"
              prop="system_template"
            >
              <el-select
                v-model="_selectedTemplate"
                v-cancel-read-only
                :placeholder="
                  $t('pages.vouchers.edit.form.system_template.placeholder')
                "
                filterable
              >
                <el-option
                  v-for="template in voucherSystemTemplates"
                  :key="template.id"
                  :value="template.id"
                  :label="template.name"
                />
              </el-select>
            </el-form-item>

            <!-- Type -->
            <el-form-item
              :label="$t('pages.vouchers.edit.form.type.label')"
              prop="type"
            >
              <el-radio-group
                v-model="form.type"
                size="large"
                :aria-label="$t('pages.vouchers.edit.form.type.label')"
              >
                <el-radio-button label="discount">
                  <div class="w-16">
                    {{ $t('pages.vouchers.edit.form.type.values.discount') }}
                  </div>
                </el-radio-button>
                <el-radio-button label="amount">
                  <div class="w-16">
                    {{ $t('pages.vouchers.edit.form.type.values.amount') }}
                  </div>
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

            <!-- Currency -->
            <el-form-item
              v-if="isAmount && currencies.length > 1"
              :label="$t('pages.vouchers.edit.form.currency.label')"
              prop="currency"
            >
              <th-currency-select
                id="currency"
                v-model="form.currency"
                :allowed-currencies="currencies"
              />
            </el-form-item>

            <!-- Amount -->
            <el-form-item
              v-if="isAmount"
              :label="$t('common.forms.labels.amount')"
              prop="amount"
            >
              <th-currency-input
                id="amount"
                v-model="form.amount"
                prominent
                :currency="currency"
              />
            </el-form-item>

            <!-- Percentage -->
            <el-form-item
              v-if="isDiscount"
              prop="discount_rate"
              :label="$t('pages.vouchers.edit.form.rate.label')"
            >
              <th-number-input
                id="discount_rate"
                v-model="form.discount_rate"
                clearable-is-left
                percent
                class="w-56"
                :upper-limit="1.0"
                :locale="$i18n.locale"
              />
            </el-form-item>

            <!-- Amount when issued -->
            <el-form-item
              :label="$t('pages.vouchers.edit.form.amount_issued_at.label')"
              prop="amount_issued_at"
            >
              <th-currency-input
                v-model="form.amount_issued_at"
                disabled
                :currency="currency"
              />
            </el-form-item>

            <!-- Minimum Top-up -->
            <el-form-item
              v-if="isAmount"
              :label="
                $t('pages.vouchers.edit.form.increment_amount_minimum.label')
              "
              prop="increment_amount_minimum"
            >
              <th-currency-input
                id="increment_amount_minimum"
                v-model="form.increment_amount_minimum"
                :currency="currency"
              />
            </el-form-item>

            <!-- Active -->
            <el-form-item prop="active">
              <el-checkbox v-model="form.active">
                {{ $t('pages.vouchers.edit.form.active.label') }}
              </el-checkbox>
            </el-form-item>

            <!-- Code -->
            <el-form-item prop="code">
              <template #label>
                <div class="flex-row">
                  <div class="mr-7">
                    {{ $t('pages.vouchers.edit.form.code.label') }}
                  </div>
                  <th-popover :text="popovers.code" placement="bottom-start" />
                </div>
              </template>
              <el-input v-model="form.code" />
            </el-form-item>

            <!-- Allowed Region -->
            <el-form-item
              :label="$t('pages.vouchers.edit.form.region.label')"
              prop="regions"
            >
              <el-select
                v-model="form.regions"
                clearable
                multiple
                :placeholder="$t('pages.vouchers.edit.form.region.placeholder')"
              >
                <el-option
                  v-for="item in availableCountries"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <!-- Attributes -->
            <attributes ref="attributes" v-model="attributes" />
          </el-col>

          <el-col
            class="form-column"
            :xs="24"
            :sm="18"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <!-- Expiration Date -->
            <el-form-item
              class="w-56"
              prop="expires_at"
              :label="$t('pages.vouchers.edit.form.expires_at.label')"
            >
              <date-picker-input
                v-model="form.expires_at"
                :type="dateTypes.DATE"
                :placeholder="
                  $t('pages.vouchers.edit.form.expires_at.day.placeholder')
                "
              />
            </el-form-item>

            <!-- Redemption is valid from -->
            <el-form-item
              class="w-56"
              prop="redemption_valid_from"
              :label="
                $t('pages.vouchers.edit.form.redemption_valid_from.label')
              "
            >
              <date-picker-input
                v-model="form.redemption_valid_from"
                :type="dateTypes.DATE_TIME"
                :date-format="dateTimeFormat"
                :placeholder="
                  $t(
                    'pages.vouchers.edit.form.redemption_valid_from.placeholder'
                  )
                "
              />
            </el-form-item>

            <!-- Charge is valid from -->
            <el-form-item
              class="w-56"
              prop="charge_valid_from"
              :label="$t('pages.vouchers.edit.form.charge_valid_from.label')"
            >
              <date-picker-input
                v-model="form.charge_valid_from"
                :type="dateTypes.DATE_TIME"
                :date-format="dateTimeFormat"
                :placeholder="
                  $t(
                    'pages.vouchers.edit.form.redemption_valid_from.placeholder'
                  )
                "
              />
            </el-form-item>

            <!-- Format Type -->
            <el-form-item
              :label="$t('pages.vouchers.edit.form.format_type.label')"
              prop="format_type"
            >
              <el-select
                id="format_type"
                v-model="form.format_type"
                :placeholder="
                  $t('pages.vouchers.edit.form.format_type.placeholder')
                "
              >
                <el-option
                  v-for="item in availableFormatTypes"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>

            <!-- Format -->
            <el-form-item prop="format">
              <template #label>
                <div class="flex-row">
                  <label for="format" class="mr-7">
                    {{ $t('pages.vouchers.edit.form.format.label') }}
                  </label>
                  <th-popover
                    :text="popovers.format"
                    placement="bottom-start"
                  />
                </div>
              </template>
              <el-input id="format" v-model="voucherFormat" />
            </el-form-item>

            <!-- Voucher available only for these customer -->
            <el-form-item
              :label="$t('pages.vouchers.edit.form.bounded_customers.label')"
              prop="bounded_customers"
            >
              <customers-select v-model="customers" />
            </el-form-item>

            <!-- Comment -->
            <el-form-item
              :label="$t('pages.vouchers.edit.form.comment.label')"
              prop="comment"
            >
              <el-input v-model="form.comment" type="textarea" />
            </el-form-item>

            <!-- Initial Issuer -->
            <el-form-item
              :label="$t('pages.vouchers.edit.form.default_user.label')"
              prop="user"
            >
              <el-select
                v-if="isNew"
                v-model="user"
                :placeholder="
                  $t('pages.vouchers.edit.form.default_user.placeholder')
                "
                :disabled="!isNew"
              >
                <el-option
                  v-for="subUser in subUsersList"
                  :key="subUser.value"
                  :label="subUser.label"
                  :value="subUser.value"
                />
              </el-select>
              <div v-else>
                {{ getIssuerName() }}
              </div>
            </el-form-item>

            <!-- Constraints -->
            <constraints
              v-model="form.constraints"
              @constraints-updated="updateConstraints"
            />
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import omit from 'just-omit'
import safeGet from 'just-safe-get'
import compare from 'just-compare'
import typeOf from 'just-typeof'
import { mapGetters } from 'vuex'
import { availableCountries } from './voucherConst.js'
import Constraints from './constraints'
import Attributes from './attributes'
import CustomersSelect from './customers-select'
import DatePickerInput from '@/components/inputs/date-picker'
import { dateTypes } from '@/constants/date'

function genDefaultForm({ currency = null } = {}) {
  return {
    active: true,
    regions: [],
    currency,
    type: 'amount',
    format_type: null,
    format: null,
    code: null,
    expires_at: null,
    redemption_valid_from: null,
    charge_valid_from: null,
    comment: null,
    amount: null,
    amount_issued_at: null,
    issuer: null,
    metadata: null,
    system: null,
    increment_amount_minimum: null,
    discount_rate: null,
    constraints: {}
  }
}

function genDefaultAttributes() {
  return {
    issuable: true,
    reissuable: false,
    partial_redemption: false,
    limited_to_region: true,
    refundable: true,
    exchange_for_cash: true,
    restriction_single_transaction: false,
    is_campaign: false
  }
}

export default {
  name: 'VouchersForm',
  components: {
    DatePickerInput,
    Constraints,
    Attributes,
    CustomersSelect
  },
  props: {
    isNew: {
      type: Boolean,
      required: true
    }
  },
  data() {
    const form = genDefaultForm()
    return {
      resources: {},
      dateTypes,
      dateTimeFormat: this.$date.getDateTimeFormat(),
      dateFormat: this.$date.getDateFormat(),
      loading: false,
      form,
      allCheckboxes: false,
      valid: false,
      attributes: genDefaultAttributes(),
      selectedTemplate: null,
      availableCountries: availableCountries,
      availableFormatTypes: ['alphanumeric', 'numeric', 'alphabetic'],
      customers: [],
      customersPayload: [],
      rules: {
        format: [
          {
            required: true,
            message: this.$t(
              'pages.vouchers.edit.form.format.validation.errors.required'
            ),
            trigger: 'change'
          }
        ],
        format_type: [
          {
            required: true,
            message: this.$t(
              'pages.vouchers.edit.form.format_type.validation.errors.required'
            ),
            trigger: 'change'
          }
        ],
        system: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ],
        currency: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ]
      },
      payload: {},
      fields: [
        'active',
        'regions',
        'currency',
        'type',
        'format_type',
        'format',
        'amount',
        'amount_issued_at',
        'code',
        'expires_at',
        'comment',
        'issuable',
        'reissuable',
        'partial_redemption',
        'limited_to_region',
        'refundable',
        'mutable',
        'exchange_for_cash',
        'restriction_single_transaction',
        'is_campaign',
        'system',
        'increment_amount_minimum'
      ],
      subUsersList: []
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'Config/getAvailableCurrencies',
      defaultCurrency: 'Config/getCurrentDefaultCurrency',
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    user: {
      get() {
        return this.form.issuer
      },
      set(value) {
        const user =
          this.subUsersList.find((user) => user.value === value) || {}
        this.form.issuer = value
        // setting metadata to be consistant with legacy data
        this.form.metadata = {
          ...this.form.metadata,
          issuer: {
            name: user.label,
            client: user.value
          }
        }
      }
    },
    _selectedTemplate: {
      get() {
        return this.selectedTemplate
      },
      set(val) {
        this.selectedTemplate = val
        const template =
          this.voucherSystemTemplates.find((vst) => vst.id === val) || {}
        this.inheritFromTemplate(template.attributes || {})
      }
    },
    _selectedSystem: {
      get() {
        return this.form.system
      },
      set(val) {
        this.form.system = val
        this._selectedTemplate = null
      }
    },
    voucherSystemTemplates() {
      if (Array.isArray(this.resources.voucher_systems) && this.form.system) {
        const system =
          this.resources.voucher_systems.find(
            (vs) => vs.id === this.form.system
          ) || {}
        return system.templates || []
      }
      return []
    },
    popovers() {
      return {
        format: this.$t('pages.vouchers.edit.form.format.tooltip'),
        code: this.$t('pages.vouchers.edit.form.code.tooltip')
      }
    },
    currency() {
      if (this.form && this.form.currency) return this.form.currency
      return null
    },
    voucherFormat: {
      get() {
        return this.form.format
      },
      set(v) {
        // Mask any character with X other than space and dash
        const re = /[^-\s]/g
        const maskedFormat = v.replace(re, 'X')
        this.form.format = maskedFormat
      }
    },
    isDiscount() {
      return this.form.type === 'discount'
    },
    isAmount() {
      return this.form.type === 'amount'
    }
  },
  created() {
    this.fetchResources()
    this.setCurrency()
  },
  mounted() {
    if (!this.isNew) {
      this.fetch(this.$route.params.id)
      this.fetchBoundedCustomers(this.$route.params.id)
    } else {
      this.getSubUsersList()
    }
    if (this.isNew && this.defaultCurrency) {
      this.form.currency = this.defaultCurrency
    }
  },
  methods: {
    updateConstraints(value) {
      this.form.constraints = value
    },
    async fetchResources() {
      try {
        this.resources = await this.$resourceFetch('voucher_systems')
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    },
    async fetch(id) {
      const errorMessage = this.$t(
        'pages.vouchers.edit.form.errors.fetch.code_XXX.content'
      )
      try {
        const inst = th.vouchers()
        this.loading = true
        const { data = {} } = await inst.get(id)

        if (data.id) {
          this.handleItem(data)
        }

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          trackError: false,
          message: errorMessage
        })
      }
    },
    async getSubUsersList() {
      try {
        const inst = th.vouchers()
        const { data = {} } = await inst.getAllUsers()
        this.subUsersList = data.map((user) => ({
          value: user.user_id,
          label: user.user_name
        }))
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    },
    handleItem(item) {
      this.payload = item

      this.form = omit(item, ['updated_at', 'created_at'])

      if (this.form && !this.form.regions) this.form.regions = []

      Object.keys(this.attributes).forEach((item) => {
        this.attributes[item] = !!this.form[item]
      })
    },
    submitForm() {
      this.validate('form', (valid) => {
        if (!valid) {
          return this.$notify.warning({
            title: this.$t(
              'pages.vouchers.edit.form.warnings.invalid_inputs.title'
            ),
            message: this.$t(
              'pages.vouchers.edit.form.warnings.invalid_inputs.contents'
            )
          })
        }

        if (this.isNew) return this.create()
        if (!compare(this.customers, this.customersPayload)) {
          return this.createOrUpdateBoundedCustomers(this.payload.id)
        }
        this.alter(this.payload.id)
      })
    },
    // We want to set the expiration time to the end of the day
    getExpirationDate() {
      if (this.form?.expires_at && new Date(this.form?.expires_at)) {
        const expiresAt = new Date(this.form?.expires_at)
        expiresAt.setHours(23, 59, 59, 0)
        return expiresAt
      } else {
        return null
      }
    },
    validate(formName = 'form', cb) {
      this.$refs[formName].validate((valid) => {
        return cb(valid)
      })
    },
    async alter() {
      const errorMessage = this.$t('common.error.action.update.single', {
        resource: this.$t('common.resource.voucher.singular')
      })
      this.validate('form', async (valid) => {
        this.valid = valid

        if (!this.valid) return

        const payload = {
          ...this.form,
          ...this.attributes,
          expires_at: this.getExpirationDate()
        }

        try {
          const inst = th.vouchers()
          this.loading = true
          const { data = {} } = await inst.patch(
            omit(this.payload, ['updated_at', 'created_at']),
            payload
          )

          if (data.id) {
            this.$message({
              type: 'success',
              message: this.$t('common.success.action.update.single', {
                resource: this.$t('common.resource.voucher.singular')
              })
            })

            this.handleItem(data)
            this.createOrUpdateBoundedCustomers(this.payload.id)
          }

          this.loading = false
        } catch (err) {
          this.loading = false
          this.$logException(err, {
            message: errorMessage
          })
        }
      })
    },
    async create() {
      const errorMessage = this.$t('common.error.action.create.single', {
        resource: this.$t('common.resource.voucher.singular')
      })
      this.validate('form', async (valid) => {
        this.valid = valid

        if (!this.valid) return

        const payload = {
          ...this.form,
          ...this.attributes,
          expires_at: this.getExpirationDate()
        }

        try {
          const inst = th.vouchers()
          this.loading = true
          const { data = {} } = await inst.create(payload)

          this.loading = false
          if (data.id) {
            if (this.customers && this.customers.length) {
              await this.createOrUpdateBoundedCustomers(data.id)
            }

            this.handleItem(data)

            this.$message({
              type: 'success',
              message: this.$t('common.success.action.create.single', {
                resource: this.$t('common.resource.voucher.singular')
              })
            })

            if (this.navigationAfterCreation === 'edit') {
              this.$router.push({
                name: 'voucher-edit',
                params: { id: data.id }
              })
            } else {
              this.$router.push({ name: 'vouchers-list' })
            }
          }
        } catch (err) {
          this.loading = false
          this.$logException(err, {
            message: errorMessage
          })
        }
      })
    },
    getIssuerName() {
      const issuer = safeGet(this.form, 'metadata.issuer') || {}
      return issuer.name || issuer.client || this.form.issuer || '--'
    },
    inheritFromTemplate(templateValues = {}) {
      const defaultValues = genDefaultForm({
        currency: this.defaultCurrency
      })
      const valuesObj = { ...defaultValues, ...templateValues }

      this.form.redemption_valid_from = valuesObj.redemption_valid_from
      this.form.charge_valid_from = valuesObj.charge_valid_from
      this.form.format = valuesObj.format
      this.form.format_type = valuesObj.format_type
      this.form.currency = valuesObj.currency
      this.form.expires_at = valuesObj.expires_at
      this.form.type = valuesObj.type
      this.form.amount = valuesObj.amount
      this.form.comment = valuesObj.comment
      this.form.regions = valuesObj.regions
      this.form.active = valuesObj.active
      this.form.increment_amount_minimum = valuesObj.increment_amount_minimum
      this.form.constraints = valuesObj.constraints
    },
    async fetchBoundedCustomers(voucherId) {
      try {
        const { data = [] } = await th.vouchers().getBoundedCustomers(voucherId)
        const customerIds = data.map((customer) => customer.id)
        this.customers = this.customersPayload = customerIds
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t(
            'pages.vouchers.edit.form.errors.patch.code_XXX.content'
          )
        })
      }
    },
    async createOrUpdateBoundedCustomers(voucherId) {
      const action = this.isNew
        ? 'createBoundedCustomers'
        : 'updateBoundedCustomers'

      const shouldMapCustomers =
        this.customers &&
        this.customers.length &&
        typeOf(this.customers[0]) !== 'string'

      try {
        const customers = shouldMapCustomers
          ? this.customers.map((c) => c.id)
          : this.customers || []

        const { data = [] } = await th
          .vouchers()
          [action](voucherId, { customers })

        if (data.length && data[0].customer_id) {
          this.$message({
            type: 'success',
            message: this.$t(
              'pages.vouchers.create.messages.bounded_customers.save_success'
            )
          })
          const customerIds = data.map((item) => item.customer_id)
          this.customers = this.customersPayload = customerIds
        }
      } catch (err) {
        this.$logException(err, {
          message: this.$t(
            'pages.vouchers.edit.form.errors.patch.code_XXX.content'
          )
        })
      }
    },
    setCurrency() {
      //If has only one currency, the currency will be automatically set as the default
      if (this.currencies.length === 1) {
        this.form.currency = this.currencies[0]
      }
    }
  }
}
</script>

<style scoped>
.form-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
}

.edit-form-wrapper {
  flex-grow: 2;
  flex-shrink: 0;
  flex-basis: 80%;
  height: 100px;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.el-form {
  width: 70%;
}

.form-column {
  margin: 0;
  padding: 1em;
}

.attributes-group :deep(.el-select .el-tag) {
  color: #409eff;
  background-color: rgba(64, 158, 255, 0.1);
  border: 1px solid rgba(64, 158, 255, 0.2);
}

.el-form-item :deep(.el-form-item__label) {
  line-height: unset;
}

.el-form-item :deep(.el-form-item__content) {
  line-height: unset;
}

.el-form-item.is-success :deep(.el-input__inner),
.el-form-item.is-success :deep(.el-input__inner:focus) {
  border-color: #dcdfe6;
}

.flex-row {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.mr-7 {
  margin-right: 7px;
}
</style>
