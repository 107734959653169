<template>
  <el-form-item
    class="attributes-group"
    :label="$t('pages.vouchers.edit.form.attributes.label')"
  >
    <div v-for="item in entries" :key="item[0]" class="attribute-item">
      <el-switch
        :model-value="item[1] === true"
        :active-text="translations[item[0]]"
        @change="
          (v) => $emit('update:modelValue', { ...modelValue, [item[0]]: v })
        "
      />
    </div>
  </el-form-item>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  computed: {
    entries() {
      return Object.entries(this.modelValue)
    },
    translations() {
      return {
        exchange_for_cash: this.$t(
          'pages.vouchers.edit.properties.attributes.exchange_for_cash.label'
        ),
        issuable: this.$t(
          'pages.vouchers.edit.properties.attributes.issuable.label'
        ),
        is_campaign: this.$t(
          'pages.vouchers.edit.properties.attributes.is_campaign.label'
        ),
        limited_to_region: this.$t(
          'pages.vouchers.edit.properties.attributes.limited_to_region.label'
        ),
        mutable: this.$t(
          'pages.vouchers.edit.properties.attributes.mutable.label'
        ),
        partial_redemption: this.$t(
          'pages.vouchers.edit.properties.attributes.partial_redemption.label'
        ),
        refundable: this.$t(
          'pages.vouchers.edit.properties.attributes.refundable.label'
        ),
        reissuable: this.$t(
          'pages.vouchers.edit.properties.attributes.reissuable.label'
        ),
        restriction_single_transaction: this.$t(
          'pages.vouchers.edit.properties.attributes.restriction_single_transaction.label'
        )
      }
    }
  }
}
</script>

<style lang="css" scoped>
.attribute-item {
  padding-bottom: 1rem;
}
</style>
