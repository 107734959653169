<template>
  <div>
    <div
      v-loading="loading"
      class="select-holder"
      tabindex="0"
      @click="$thModal.show('customers-select')"
    >
      <template v-if="shownItems && shownItems.length">
        <el-tag
          v-for="item in shownItems"
          :key="item.id"
          class="mr-2"
          size="small"
        >
          {{ fullName(item) }}
        </el-tag>
      </template>
      <span v-else class="text-th-secondary-gray">
        {{
          $t('pages.vouchers.edit.form.properties.customers.select.placeholder')
        }}
      </span>
    </div>

    <th-modal
      name="customers-select"
      height="800px"
      width="1200px"
      :title="$t('pages.vouchers.edit.form.properties.customers.select.title')"
    >
      <group-items-select
        v-model="selectedItems"
        resource="customersV1"
        show-search-filter
        fuzzy-search
        :filters="filtersList"
        :resource-query="{
          deleted: false,
          limit: 50,
          location: currentLocation
        }"
        :normalise-keys="normalizeKeys"
        full-selected-object
      />

      <template #footer>
        <el-button @click="handleCancel">
          {{ $t('common.interactions.buttons.cancel') }}
        </el-button>
        <el-button type="primary" @click="handleSave">
          {{ $t('common.interactions.buttons.save') }}
        </el-button>
      </template>
    </th-modal>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import GroupItemsSelect from '@/components/group-items-select'
import pAll from 'p-all'
import compare from 'just-compare'
import typeOf from 'just-typeof'
import fullName from '@/utils/full-name'

const SHOWN_ITEMS_LIMIT = 3

export default {
  components: {
    GroupItemsSelect
  },
  props: {
    modelValue: {
      type: [Array, null],
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      selectedItems: [],
      shownItems: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    filtersList() {
      return [
        {
          name: 'firstname',
          type: 'input',
          label: this.$t('pages.customers.all.filters.firstname.label')
        },
        {
          name: 'lastname',
          type: 'input',
          label: this.$t('pages.customers.all.filters.lastname.label')
        },
        {
          name: 'customer_number',
          type: 'input',
          label: this.$t('pages.customers.all.filters.customer_number.label')
        },
        {
          name: 'postal_code',
          type: 'input',
          label: this.$t('pages.customers.all.filters.zip.label')
        },
        {
          name: 'address',
          type: 'input',
          label: this.$t('pages.customers.all.filters.address.label')
        }
      ]
    },
    normalizeKeys() {
      return (customer) => ({
        ...customer,
        title_main: fullName(customer),
        title_secondary: customer.customer_number
      })
    }
  },
  watch: {
    modelValue(newItems, oldItems) {
      if (Array.isArray(newItems) && !compare(newItems, oldItems)) {
        this.selectedItems = this.modelValue
        this.fetchSelectedItemsData()
      }
    }
  },
  methods: {
    handleCancel() {
      this.selectedItems = this.modelValue || []
      this.$thModal.hide('customers-select')
    },
    handleSave() {
      const customers = this.selectedItems.length
        ? this.selectedItems
        : undefined
      this.$emit('update:modelValue', customers)
      this.$thModal.hide('customers-select')
      this.fillShownItems(customers)
    },
    fillShownItems(items = []) {
      const slicedItems = items.slice(0, SHOWN_ITEMS_LIMIT)
      const restNumber = items.length - SHOWN_ITEMS_LIMIT
      const restElement =
        restNumber > 0 ? [{ firstname: `+ ${restNumber}`, id: Date.now() }] : []
      this.shownItems = [...slicedItems, ...restElement]
    },
    async fetchSelectedItemsData() {
      if (!Array.isArray(this.modelValue) || !this.modelValue.length) return

      if (typeof this.modelValue[0] === 'string') {
        try {
          this.loading = true
          const inst = th.customersV1()
          const actions = this.modelValue.map((item) => () => inst.get(item))
          const res = await pAll(actions)
          const itemsData = res.map((item) => item.data)
          this.selectedItems = itemsData
          this.$emit('update:modelValue', itemsData)
          this.fillShownItems(itemsData)
        } catch (err) {
          this.$logException(err, { trackError: false })
        } finally {
          this.loading = false
        }
      }

      if (typeOf(this.modelValue[0]) === 'object') {
        this.fillShownItems(this.modelValue)
      }
    },
    fullName(customer) {
      return fullName(customer)
    }
  }
}
</script>

<style scoped>
.select-holder {
  width: 100%;
  -webkit-appearance: none;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--label-text-color);
  display: inline-block;
  min-height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;
}
</style>
